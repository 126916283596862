package tta.destinigo.talktoastro.feature_expert_search

import androidx.compose.animation.ExperimentalAnimationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.style.TextAlign
import co.touchlab.kermit.Logger
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.filter
import tta.destinigo.talktoastro.feature_expert_search.data.SearchDisplay
import tta.destinigo.talktoastro.feature_expert_search.data.SearchState
import tta.destinigo.talktoastro.feature_expert_search.data.SearchExpertRequest
import tta.destinigo.talktoastro.feature_expert_search.data.CategoryConsultListRowClickListener
import tta.destinigo.talktoastro.shared.data.RequestConsultant
import tta.destinigo.talktoastro.shared.persentation.CommonTopAppBar
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.shared.theme.background_color

@OptIn(ExperimentalAnimationApi::class, ExperimentalMaterial3WindowSizeClassApi::class,
    ExperimentalMaterial3Api::class, FlowPreview::class
)
@Composable
fun HomeScreen(
    modifier: Modifier = Modifier,
    viewModel: SearchScreenComponent,
    navigateToTutorial: (String) -> Unit,
    state: SearchState = rememberSearchState()
) {

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_ELSE
    }
    val coroutineScope = rememberCoroutineScope()
    Box( modifier = Modifier
        .fillMaxSize()
        .background(background_color),
        contentAlignment = Alignment.Center) {

        Column(
            modifier = widthModifier.fillMaxSize().background(background_color),
        ) {
            CommonTopAppBar(
                title = "Search Experts",
                modifier = Modifier,
                onBackClick = { viewModel.onBackClick() }
            )
            SearchBar(
                query = state.query,
                onQueryChange = { query ->
                    state.query = query
                },
                onSearchFocusChange = { state.focused = it },
                onClearQuery = { state.query = TextFieldValue("") },
                onBack = { state.query = TextFieldValue("") },
                searching = state.searching,
                focused = state.focused,
                modifier = modifier
            )

//            LaunchedEffect(key1 = state.query.text) {
//                coroutineScope.launch {
//                    delay(300) // Debounce for 300ms
//                    if (state.query.text.isNotEmpty()) {
//                        Logger.d { "query test is not empty" }
//                        state.searching = true
//                        val results = viewModel.getExpertByRemote(
//                            SearchExpertRequest(type = "All", searchQuery = state.query.text)
//                        )
//                        state.searchResults = results
//                        state.searching = false
//                    } else {
//                        Logger.d { "query test is empty" }
//                        state.searchResults = emptyList()
//                    }
//                }
//            }


            LaunchedEffect(key1 = state.query.text) {
                snapshotFlow { state.query.text }
                    .debounce(300) // Debounce input for 300ms
                    .filter { it.isNotEmpty() } // Only process non-empty queries
                    .distinctUntilChanged() // Avoid repeated API calls for the same input
                    .collect { query ->
                        Logger.d { "Processing search query: $query" }
                        state.searching = true
                        try {
                            val results = viewModel.getExpertByRemote(
                                SearchExpertRequest(type = "All", searchQuery = query)
                            )
                            state.searchResults = results
                        } catch (e: Exception) {
                            Logger.e(e) { "Error fetching search results: ${e.message}" }
                            state.searchResults = emptyList() // Fallback to empty list
                        } finally {
                            state.searching = false
                        }
                    }
            }

            when (state.searchDisplay) {
                SearchDisplay.InitialResults -> {
                    Logger.d { "Start typing to search experts..." }
                }

                SearchDisplay.NoResults -> {
                    Text(text = "No results found for '${state.query.text}'.", textDecoration = null, textAlign = TextAlign.Center )
                    Logger.d { "No results found for '${state.query.text}'." }
                    // showToast("${state.searchResults.size}")
                }

                SearchDisplay.Suggestions -> {
                    Logger.d { "Suggestions..." }
                }

                SearchDisplay.Results -> {
                    //showToast("${state.searchResults.size}")
                    LazyColumn(modifier = Modifier.fillMaxSize()) {
                        items(state.searchResults.size) { index ->
                            val data = state.searchResults[index]
                            SearchConsultantItem(
                                expert = data,
                                position = index,
                                listener = object : CategoryConsultListRowClickListener {
                                    override fun onItemClicks(
                                        position: Int,
                                        expert: RequestConsultant
                                    ) {
                                        expert.id?.let { viewModel.onClickExpertCard(it) }
                                        Logger.d { "Expert clicked: ${expert.full_name} at position $position" }
                                    }

                                    override fun onChatClick(
                                        position: Int,
                                        expert: RequestConsultant
                                    ) {
                                        Logger.d { "Expert clicked: ${expert.full_name} at position $position" }
                                    }

                                    override fun onCallClick(
                                        position: Int,
                                        expert: RequestConsultant
                                    ) {
                                        Logger.d { "Expert clicked: ${expert.full_name} at position $position" }
                                    }

                                    override fun onConsultNowClick(
                                        position: Int,
                                        expert: RequestConsultant
                                    ) {
                                        Logger.d { "Expert clicked: ${expert.full_name} at position $position" }
                                    }
                                },
                                isFreeCallApplicable = false
                                //isFreeCallApplicable = data.isFreeCall == 1 // Ensure this is provided in your data
                            )
                        }
                    }
                }

                else -> {
                    Logger.d { "Unhandled State" }
                }
            }

        }
    }
}
