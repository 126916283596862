package tta.destinigo.talktoastro.feature_expert_search

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.baseline_language_24
import tta.destinigo.talktoastro.resources.ic_filled_star
import tta.destinigo.talktoastro.resources.ic_heart_primary_color
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.feature_expert_details.expert_detail_page.widget.ConsultantRating
import tta.destinigo.talktoastro.feature_expert_search.data.CategoryConsultListRowClickListener
import tta.destinigo.talktoastro.resources.baseline_person_4_24
import tta.destinigo.talktoastro.shared.data.RequestConsultant
import tta.destinigo.talktoastro.shared.persentation.ExpertPriceRow
import tta.destinigo.talktoastro.shared.theme.Scale.CORNER_SMALL
import tta.destinigo.talktoastro.shared.theme.Scale.ELEVATION_SMALL
import tta.destinigo.talktoastro.shared.theme.profile_image_background_color

@Composable
fun SearchConsultantItem(
    expert: RequestConsultant,
    position: Int,
    listener: CategoryConsultListRowClickListener,
    isFreeCallApplicable: Boolean
) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(8.dp)
            .clickable { listener.onItemClicks(position, expert) },
        shape = RoundedCornerShape(CORNER_SMALL),
        elevation = CardDefaults.elevatedCardElevation(ELEVATION_SMALL),
        colors = CardDefaults.cardColors(Color.White)
    ) {
        Row(
            modifier = Modifier
                .padding(4.dp)
                .fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            // User Image Column
            Column(
                modifier = Modifier
                    .width(100.dp)
                    .wrapContentHeight(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                val imageModifier = Modifier
                    .size(84.dp)
                    .border(width = 2.dp, color = Color.Gray, shape = CircleShape)
                    .clip(CircleShape)
                    .background(profile_image_background_color)

                if (expert.profile_image_url.isNullOrBlank()) {
                    Image(
                        painter = painterResource(Res.drawable.baseline_person_4_24),
                        contentDescription = null,
                        modifier = imageModifier,
                        contentScale = ContentScale.Crop
                    )
                } else {
                    AsyncImage(
                        model = expert.profile_image_url,
                        contentDescription = "Expert Image",
                        modifier = imageModifier,
                        contentScale = ContentScale.Crop
                    )
                }

                Spacer(modifier = Modifier.height(8.dp))

                Text(
                    text = "${expert.year_of_experience} yrs",
                    color = Color.Black,
                    style = MaterialTheme.typography.bodyMedium
                )

                Spacer(modifier = Modifier.height(4.dp))

                Row(verticalAlignment = Alignment.CenterVertically) {
                    Icon(
                        painter = painterResource(Res.drawable.ic_filled_star),
                        contentDescription = null,
                        tint = Color(0xFFFF9C27),
                        modifier = Modifier.size(16.dp)
                    )
                    ConsultantRating(expert.consultant_rating?.toDouble())
                }
            }

            Spacer(modifier = Modifier.width(8.dp))

            // Details Column
            Column(
                modifier = Modifier
                    .weight(1f)
                    .padding(end = 8.dp)
            ) {
                Text(
                    text = expert.full_name.orEmpty(),
                    style = MaterialTheme.typography.headlineSmall,
                    color = Color.Black,
                    fontSize = 16.sp,
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis
                )

                Spacer(modifier = Modifier.height(2.dp))

                Text(
                    text = expert.skills.orEmpty(),
                    style = MaterialTheme.typography.bodyMedium,
                    color = Color.Black,
                    maxLines = 2,
                    fontSize = 14.sp,
                    overflow = TextOverflow.Ellipsis
                )

                Spacer(modifier = Modifier.height(2.dp))

                Row(verticalAlignment = Alignment.CenterVertically) {
                    Icon(
                        painter = painterResource(Res.drawable.baseline_language_24),
                        contentDescription = null,
                        modifier = Modifier.size(14.dp),
                        tint = Color.Black
                    )
                    Spacer(modifier = Modifier.width(4.dp))
                    Text(
                        text = expert.language.orEmpty(),
                        style = MaterialTheme.typography.bodyMedium,
                        color = Color.Black,
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis
                    )
                }

                Spacer(modifier = Modifier.height(2.dp))

                //ExpertCallPrice(expert, if (isFreeCallApplicable) false else expert.isFreeCall == 1)

                ExpertPriceRow(
                    isFreeCallApplicable = isFreeCallApplicable,
                    //isFreeCallApplicable = if(consultant.isFreeCall?.equals(1) == true) true else false,
                    callPerMinutePrice = expert.callPerMinutePrice.toString(),
                    expertPrice = expert.expert_price ?: 0,
                    winywayPrice = expert.winyway_price ?: 0,
                    flashPrice = expert.flashPerMinutePrice,
                    horizontalArrangement = Arrangement.Start
                )

                //Spacer(modifier = Modifier.height(2.dp))

//                Row(
//                    modifier = Modifier.fillMaxWidth(),
//                    horizontalArrangement = Arrangement.SpaceBetween,
//                    verticalAlignment = Alignment.CenterVertically
//                ) {
//                    Box(
//                        modifier = Modifier.weight(1f)
//                    ) {
//                        CallChartActionButton(
//                            text = "CHAT",
//                            icon = Icons.AutoMirrored.Filled.Chat,
//                            //icon = painterResource(Res.drawable.new_caht2),
//                            onClick = { },
//                            buttonColor = chat_button_background_color,
//                            consultantCallAvailability =  expert.consultantChatAvailability()!!
//                        )
//                    }
//
//                    Spacer(modifier = Modifier.width(3.dp))
//
//                    Box(
//                        modifier = Modifier.weight(1f)
//                    ) {
//                        CallChartActionButton(
//                            text = "CALL",
//                            icon = Icons.Filled.Call,
//                           // icon = painterResource(Res.drawable.new_call1),
//                            onClick = {  },
//                            buttonColor = call_button_background_color,
//                            consultantCallAvailability =  expert.consultantCallAvailability()!!
//                        )
//                    }
//                }
            }

//            // Add to favourite button
//            Box(modifier = Modifier.align(Alignment.Top)) {
//                ActionIconButton(
//                    icon = painterResource(Res.drawable.ic_heart_primary_color),
//                    onClick = {
//                        //listener.onConsultNowClick(position, expert)
//                    },
//                    onClickSuccess = false
//                    //contentDescription = "Add to Favourites"
//                )
//            }
        }
    }
}
