package tta.destinigo.talktoastro.feature_expert_search.data.api


import tta.destinigo.talktoastro.feature_expert_details.data.response.Data
import tta.destinigo.talktoastro.feature_expert_details.data.response.SearchExpertResponse
import tta.destinigo.talktoastro.feature_expert_search.data.SearchExpertRequest
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import tta.destinigo.talktoastro.shared.remote.KtorApi
import tta.destinigo.talktoastro.shared.remote.Resources

class SearchExpertApi: KtorApi() {
    companion object {
        const val SEARCH_EXPERT_QUERY = "search-consultant"
    }

    private suspend fun getExpertList(request: SearchExpertRequest) = client.post {
        url(SEARCH_EXPERT_QUERY)
        contentType(ContentType.Application.Json)
        setBody(request)
    }.body<SearchExpertResponse>()



    suspend fun getExpertListRemote(request: SearchExpertRequest): Resources<Data> {

        return withContext(Dispatchers.Default) {
            try {
                val response = getExpertList(request)
                if (response.status.not()) {
                    return@withContext Resources.Error(
                        response.message
                    )
                }
                return@withContext Resources.Success(response.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

}