package tta.destinigo.talktoastro.feature_expert_search

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.text.input.TextFieldValue
import tta.destinigo.talktoastro.feature_expert_search.data.SearchState
import tta.destinigo.talktoastro.shared.data.RequestConsultant

@Composable
fun rememberSearchState(
    query: TextFieldValue = TextFieldValue(""),
    focused: Boolean = false,
    searching: Boolean = false,
    suggestions: List<RequestConsultant> = emptyList(),
    searchResults: List<RequestConsultant> = emptyList()
): SearchState {
    return remember {
        SearchState(
            query = query,
            focused = focused,
            searching = searching,
            suggestions = suggestions,
            searchResults = searchResults
        )
    }
}